$(function() {

    $('.js-zoomimage').on('click', function() {
        var t = $( $(this).data('target') );
        var i = $(this).data('src-large');
        if (t.length > 0) {
            t.children('img').attr('src', i);
        }
    });

});